import styled from 'styled-components';

const StyledPost = styled.section`
  .post__date {
    display: block;
    margin-top: 32px;
  }
`;

export default StyledPost;
