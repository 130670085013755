import * as React from 'react';

import StyledPost from './post.styles';

// Particles
import ROUTES from 'routes';
import { datePretty, parseMarkdown } from 'corigan';
import { ProtectedRoute } from 'corigan';

// Components
import { Link } from 'corigan';
import { Breadcrumbs, Card } from 'corigan';
import { Grid, Row, Col } from 'corigan';
import { Page } from 'templates';

type PostProps = {
  pageContext: any;
};

const Post: React.FC<PostProps> = (props: PostProps) => {
  const markdown = props?.pageContext?.markdown;
  if (!markdown) return null;

  const markdownContent = parseMarkdown(markdown);
  const { content, date, title } = markdownContent;

  const classList = `post`;

  return (
    <ProtectedRoute>
      <Page>
        <Grid>
          <Row>
            <Col>
              <Breadcrumbs>
                <Link href={ROUTES.dashboard}>Portal Dashboard</Link>
                <h1>{title}</h1>
              </Breadcrumbs>
            </Col>
          </Row>
          <Row>
            <Col xl={7}>
              <Card>
                <StyledPost className={classList}>
                  {content}
                  {date && <time className="post__date">Posted on {datePretty(date)}</time>}
                </StyledPost>
              </Card>
            </Col>
          </Row>
        </Grid>
      </Page>
    </ProtectedRoute>
  );
};

Post.defaultProps = {};

export default Post;
